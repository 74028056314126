import Vue from "vue";
import 'vant/lib/index.css'
import { Tabbar, TabbarItem, Icon, Button, Field, Popup, Swipe, SwipeItem, NoticeBar, Tab, Tabs, Overlay, CouponCell, CouponList, Checkbox, CheckboxGroup, Form, Picker, Switch, ActionSheet, Radio, RadioGroup, Empty, Toast, PullRefresh, Uploader,Loading } from 'vant'

Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Icon)
Vue.use(Button)
Vue.use(Field)
Vue.use(Popup)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(NoticeBar)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Overlay)
Vue.use(CouponCell)
Vue.use(CouponList)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Form)
Vue.use(Picker)
Vue.use(Switch)
Vue.use(ActionSheet)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Empty)
Vue.use(Toast)
Vue.use(PullRefresh)
Vue.use(Uploader)
Vue.use(Loading)