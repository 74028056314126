<template>
  <div id="app">
      <router-view></router-view>
      
      <Footer v-show="$route.meta.show"></Footer>
      <div class="bottom-safety-zone"></div>
  </div>
</template>

<script>
import Footer from '@/components/Footer/index.vue'
export default {
  name: 'App',
  components: {
    Footer
  },
  created(){
    this.onchangeWindowSize();
    window.onresize = this.onchangeWindowSize;
  },
  methods:{
    onchangeWindowSize(){
      const rootFontSize = window.innerWidth * (16 / 812);
      document.getElementsByTagName(
        "html"
      )[0].style.fontSize = `${rootFontSize}px`;
    }
  }
}
</script>

<style>
.bottom-safety-zone{
  height: 5rem;
  background-color: #f7f8fa;
}

body {
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom);
  /* 兼容 iOS >= 11.2 */
}
</style>
