<template>
  <div>
    <div class="footer-center" v-show="hidshow">
      <van-tabbar v-model="active" route active-color="#2977ff">
        <van-tabbar-item icon="home-o" to="/home">首页</van-tabbar-item>
        <van-tabbar-item icon="logistics" to="/carownerservice">车主服务</van-tabbar-item>
        <van-tabbar-item icon="shield-o" to="/healthmanagement">健康管理</van-tabbar-item>
        <van-tabbar-item icon="balance-pay" to="/virtualcardcoupon/submenu">虚拟卡券</van-tabbar-item>
        <van-tabbar-item icon="contact" to="/mypage">我的</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      docmHeight: window.innerHeight, //默认屏幕高度
      showHeight: window.innerHeight, //实时屏幕高度
      hidshow: true //显示或者隐藏footer
    };
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.showHeight = window.innerHeight
      console.log('this.showHeight:' + this.showHeight);
      console.log('this.docmHeight:' + this.docmHeight);
      if (this.docmHeight - this.showHeight > 100) {
        //Android系统: 软键盘弹出 隐藏footer
        this.hidshow = false
      } else {
        //Android系统: 软键盘收起 显示footer
        this.hidshow = true
      }
    });
  },
};
</script>

<style scoped lang="scss">
::v-deep .van-icon {
  font-weight: 600;
  // font-size: 3.5rem;
}

::v-deep .van-hairline--top-bottom {
  min-height: 6.8rem;
}

::v-deep .van-tabbar-item__text {
  font-size: 1.5rem;
}
</style>