// 对于axios进行二级封装
import axios from "axios";
import { Dialog } from 'vant';
// // 1:利用axios对象的方法create，去创建一个axios实例
// // 2:request就是axios，只不过稍微配置以下
// const requests = axios.create({
//     //配置对象
//     // 基础路径，发送请求的时候，路径当中会出先api,就不用请求链接的时候一个一个的去加了
//     baseURL:'/api',
//     // 代表请求超时的时间5S
//     timeout:5000,
// });

// // 请求拦截器：在发请求之前，请求拦截器可以检测到，可以在请求发出去之前做一些事情
// requests.interceptors.request.use((config)=>{
//     //config:配置对象，对象里面有一个属性，header请求头
//     return config;
// });

// // 响应拦截器
// requests.interceptors.response.use((res)=>{
//     // 这是服务器成功的回调函数：服务器响应数据回来以后，响应拦截器可以检测到，可以做一些事情
//     return res.data;
// },(error)=>{
//     // 响应失败的回调函数
//     return Promise.reject(new Error('faile'));
// })

// export function WxRequest(config) {
//     const Wxinstants  = axios.create({
//       baseURL: '/api',
//       timeout: 5000,
//     })

//     Wxinstants .interceptors.request.use(config => {
//       return config
//     },error => {

//     })

//     Wxinstants .interceptors.response.use(config => {
//       return config.data
//     },error => {

//     })

//     return Wxinstants (config)
//   }
const Wxinstants = axios.create({
  baseURL: '/api',
  timeout: 5000,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

Wxinstants.interceptors.request.use(config => {
  return config
}, error => {

})

Wxinstants.interceptors.response.use(res => {
  
  const { data, code, message } = res.data

  switch (code) {
    case 0:
      if(!data){
        return true;
      }
      return data;
    case 403:
      // Dialog.alert({
      //   title: '提示',
      //   message: message,
      // }).then(() => {
        if (window.localStorage.getItem("token")) {
          window.localStorage.removeItem('token');
        }
        if(window.location.href.match(/info-enter/g)){
          window.location.href=window.location.origin+'/#/info-enter'
        }else{
          window.location.href=window.location.origin
        }
      // });
      break;
    default:
      alert(message);
      break;
  }
  return Promise.reject(message);
}, (error) => {
  alert(error.message)
})

export default Wxinstants;