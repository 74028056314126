//这路由里面的配置信息抽出来，单独放在一个js当中，然后导出去
export default [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        meta: { title: '首页', show: true },
        component: () => import('@/view/Home')
    },
    {
        path: '/carownerservice',
        name: 'hcarownerservice',
        meta: { title: '车主服务', show: true },
        component: () => import('@/view/CarOwnerService')
    },
    {
        path: '/healthmanagement',
        name: 'healthmanagement',
        meta: { title: '健康管理', show: true },
        component: () => import('@/view/HealthManagement')
    },
    {
        path: '/virtualcardcoupon',
        name: 'virtualcardcoupon',
        // meta:{title:'虚拟卡券',show:true},
        component: () => import('@/view/VirtualCardCoupon'),
        children: [
            {
                path: 'submenu',
                name: 'submenu',
                meta: { title: '虚拟卡券', show: true },
                component: () => import('@/view/VirtualCardCoupon/submenu')
            }
        ]
    },
    {
        path: '/mypage',
        name: 'mypage',
        meta: { title: '我的', show: true },
        component: () => import('@/view/MyPage')
    },
    {
        path: '/creditsexchange',
        name: 'creditsexchange',
        meta: { title: '积分兑换', show: true },
        component: () => import('@/view/CreditsExchange')
    },
    {
        path: '/productdetails',
        name: 'productdetails',
        meta: { title: '商品详情', show: false },
        component: () => import('@/view/Proditdetails')
    },
    {
        path: '/orderpage',
        name: 'orderpage',
        meta: { title: '订单列表', show: true },
        component: () => import('@/view/Orderpage'),
        children: [
            {
                path: 'unpaid',
                name: 'unpaid',
                component: () => import('@/view/Orderpage/unpaid')
            }
        ]
    },
    {
        path: '/orderdetails',
        name: 'orderdetails',
        meta: { title: '订单详情', show: true },
        component: () => import('@/view/Orderdetails')
    },
    {
        path: '/exchange',
        name: 'exchange',
        meta: { title: '积分兑换', show: true },
        component: () => import('@/view/Exchange'),
        children: [
            {
                path: 'wechatwithdrawal',
                name: 'wechatwithdrawal',
                meta: { title: '积分兑换', show: true },
                component: () => import('@/view/Exchange/Wechatwithdrawal')
            },
            {
                path: 'bankcard',
                name: 'bankcard',
                meta: { title: '积分兑换', show: true },
                component: () => import('@/view/Exchange/Bankcard')
            },
            {
                path: 'alipay',
                name: 'alipay',
                meta: { title: '积分兑换', show: true },
                component: () => import('@/view/Exchange/Alipay')
            }
        ]
    },
    {
        path: '/redemptionsuccessful',
        name: 'redemptionsuccessful',
        meta: { title: '提交成功', show: false },
        component: () => import('@/view/Redemptionsuccessful')
    },
    {
        path: '/withdrawalsubmit',
        name: 'withdrawalsubmit',
        meta: { title: '提交中', show: true },
        component: () => import('@/view/WithdrawalSubmit')
    },
    {
        path: '/virtualproduct',
        name: 'virtualproduct',
        meta: { title: '商品详情', show: false },
        component: () => import('@/view/VirtualProduct')
    },
    {
        path: '/commodityoil',
        name: 'commodityoil',
        meta: { title: '商品', show: false },
        component: () => import('@/view/commodityOil')
    },
    {
        path: '/recharge',
        name: 'recharge',
        meta: { title: '商品', show: false },
        component: () => import('@/view/Recharge')
    },
    {
        path: '/carwashcoupon',
        name: 'carwashcoupon',
        meta: { title: '美容洗车', show: false },
        component: () => import('@/view/CarWashCoupon')
    },
    {
        path: '/parkingagent',
        name: 'parkingagent',
        meta: { title: '商品', show: false },
        component: () => import('@/view/ParkingAgent')
    },
    {
        path: '/bindpage',
        component: () => import('@/view/Bindpage'),
        name: 'bindpage',
        meta: { title: '手机绑定', show: false }
    },
    {
        path: '/cashier',
        name: 'cashier',
        meta: { title: '综合收银', show: false },
        component: () => import('@/view/Cashier')
    },
    {
        path: '/info-enter',
        name: 'infoEnter',
        meta: { title: '信息录入', show: false },
        component: () => import('@/view/InfoEnter/infoEnter.vue')
    },
    {
        path: '/info-enter/success',
        name: 'infoEnterSuccess',
        meta: { title: '信息录入', show: false },
        component: () => import('@/view/InfoEnter/success.vue')
    },
    {
        path: '/auth',
        name: 'auth',
        meta: { title: '授权中', show: false },
        component: () => import('@/view/auth/auth.vue')
    },
    {
        path: '/caintenancelist',
        name: 'caintenanceList',
        meta: { title: '保养列表', show: false },
        component: () => import('@/view/CaintenanceList')
    },
	{
	    path: '/dataConfirmation',
	    name: 'dataConfirmation',
	    meta: { title: '数据确认', show: false },
	    component: () => import('@/view/dataConfirmation')
	},
]