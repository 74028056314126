//同意管理项目接口的模块
//当前这个模块：API进行统一管理 
import requests from "./request";

const token = localStorage.getItem('token');

// jsapi接口
export const getwxMpJsApi = (token, url) => requests({
	url: '/user/wxMpJsApi',
	method: 'post',
	params: {
		token: token,
		url: url
	}
})
// 微信支付订单创建
export const createOrder = (token, productId, integral) => requests({
	url: '/salesOrder/createOrder',
	method: 'post',
	params: {
		token: token,
		productId: productId,
		integral: integral
	}
})

// 发送验证码
export const postSendCaptcha = (token, mobile) => requests({
	url: '/user/sendCaptcha',
	method: 'post',
	params: {
		token: token,
		mobile: mobile
	}
})

// 绑定手机号请求
export const postBindMobile = (token, mobile, captcha) => requests({
	url: '/user/bindMobile',
	method: 'post',
	params: {
		token: token,
		mobile: mobile,
		captcha,
		captcha
	}
})

// 商品列表接口
export const postProductList = () => requests({
	url: '/product/list',
	method: 'post',
	params: {
		token: token
	}
})

// 订单列表接口
export const postSalesOrderList = (token, pageNum, pageSize, orderStatus) => requests({
	url: '/salesOrder/list',
	method: 'post',
	params: {
		token: token,
		pageNum: pageNum,
		pageSize,
		orderStatus: orderStatus
	}
})

// 获取积分
export const postUserWallet = (token) => requests({
	url: '/userWallet/detail',
	method: 'post',
	params: {
		token: token
	}
})

//创建权益回收订单
export const createOrganizationOrder = (amount, licensePlateNumber, organizationCode) => requests({
	url: '/organizationReturnOrder/createOrder',
	method: 'post',
	params: {
		token,
		amount,
		licensePlateNumber,
		organizationCode
	}
})

// 创建提现订单
export const postIntegralWithdraw = (accountType, accountName, accountNo, transQuantity) => requests({
	url: '/integralWithdraw/createOrder',
	method: 'post',
	params: {
		token,
		accountType,
		accountName,
		accountNo,
		transQuantity
	}
})

//用户钱包交易记录列表
export const postUserWalletTransRecord = () => requests({
	url: '/userWalletTransRecord/list',
	method: 'post',
	params: {
		token
	}
})

//创建权益回收金额订单
export const createOrganizationOrderByAmount = (amount, organizationCode, orderNo) => requests({
	url: '/organizationReturnOrder/createOrderByAmount',
	method: 'post',
	params: {
		token,
		amount,
		organizationCode,
		orderNo
	}
})

//获取首页banner，中心图
export const postBannerList = (areaType) => requests({
	url: '/banner/list',
	method: 'post',
	params: {
		areaType
	}
})

//平安业务接口 - 保存基本信息
export const postPaCustomInfo = (customName, customId, cardNegative, cardPositive, bankAccountName, bankAccountNo,
	bankName, bankMobile) => requests({
	url: '/paCustomInfo/save',
	method: 'post',
	params: {
		customName,
		customId,
		cardNegative,
		cardPositive,
		bankAccountName,
		bankAccountNo,
		bankName,
		bankMobile
	}
})

export const uploadImg = (file) => requests({
	url: '/file/upload',
	method: 'post',
	params: {
		token
	},
	data: {
		file
	}
})

//平安业务接口 - 银行卡Ocr识别
export const bankCardOcrBase64Img = (base64Str) => {
	let formData = new FormData();
	formData.append('token', token);
	formData.append('base64Str', base64Str);
	return requests({
		url: '/file/bankCardOcrBase64Img',
		method: 'post',
		data: formData
	})
}

//数据确认-根据id获取信息
export const getBatchInfoById = (id) => requests({
	url: '/batchRecord/getBatchInfoById',
	method: 'get',
	params: {
		id: id
	}
})
//数据确认-发送短信验证码
export const postSendSmsCaptcha = (id) => requests({
	url: '/batchRecord/sendSmsCaptcha',
	method: 'post',
	params: {
		id: id
	}
})
//数据确认-数据验证保存
export const postSubmitVerifyData = (id, captcha) => requests({
	url: '/batchRecord/submitVerifyData',
	method: 'post',
	params: {
		id: id,
		captcha: captcha
	}
})
