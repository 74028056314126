import Vue from 'vue'
import App from './App.vue'
// 引入路由
import router from './router'
// 引入vant库
import '@/config/vant.config'

import *as API from '@/api'

Vue.config.productionTip = false

import wx from 'weixin-js-sdk'
Vue.prototype.$wx = wx

//路由发生变化修改页面title
router.beforeEach((to,from,next) => {
  if(to.meta.title){
    document.title = to.meta.title
  }
  next()
})

new Vue({
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$API = API
  },
  router
}).$mount('#app')